import React from "react";
import "./Footer.css";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
  return (
    <footer className="footer_container">
      <div className="image_text">
        <img src="Logo_White_NoBg.png" alt="footer logo" />
        <p className="arctura_description">
          Nunca será somente uma peça de roupa se fores tu a usá-la.
        </p>
      </div>
      <div>
        <p className="section_title">SECÇÕES</p>
        <ul className="footer_links">
          <li className="footer_link_item">
            <a href="#sobre" className="footer_link">
              Sobre
            </a>
          </li>
          <li className="footer_link_item">
            <a href="#servicos" className="footer_link">
              Serviços
            </a>
          </li>
          <li className="footer_link_item">
            <a href="#contacto" className="footer_link">
              Contacto
            </a>
          </li>
        </ul>
      </div>
      <div>
        <p className="section_title">REDES SOCIAIS & CONTACTO</p>
        <ul className="social_links">
          <li className="social_link_item">
            <a
              href="https://www.instagram.com/arctura_imageconsulting/"
              className="social_link"
            >
              <FaInstagram size={12} className="icon" />
              Instagram
            </a>
          </li>
          <li className="social_link_item">
            <a
              href="https://www.tiktok.com/@arctura_imageconsulting"
              className="social_link"
            >
              <FaTiktok size={12} className="icon" />
              Tiktok
            </a>
          </li>
          <li className="social_link_item">
            <IoMdMail size={12} className="icon" />
            telma.soares@arctura.pt
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
