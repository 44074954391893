import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Servico.css";
import { TfiArrowCircleDown, TfiArrowCircleUp } from "react-icons/tfi";

const Servico = ({ numero, titulo, texto, isOpen, onClick }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const formattedTexto = texto.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="servico_container" onClick={onClick} ref={containerRef}>
      <p className="numero">{numero}</p>
      <div className="texto_container">
        <p className="titulo">{titulo}</p>
        <p className={`texto ${isOpen ? "open" : ""}`}>{formattedTexto}</p>
      </div>
      <div className="arrow">
        {isOpen ? (
          <TfiArrowCircleUp
            size={32}
            style={{ marginTop: "8px" }}
            fill="#8a8a8a"
          />
        ) : (
          <TfiArrowCircleDown
            size={32}
            style={{ marginTop: "8px" }}
            fill="#8a8a8a"
          />
        )}
      </div>
    </div>
  );
};

Servico.propTypes = {
  numero: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  texto: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Servico;
