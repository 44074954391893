import React from "react";
import { FaInstagram, FaTiktok } from "react-icons/fa";

import "./WebMenu.css";

const WebMenu = () => {
  return (
    <header className="header_container">
      <menu>
        <a href="#sobre">Sobre</a>
        <a href="#serviços">Serviços</a>
        <a href="#contacto">Contacto</a>
      </menu>
      <div className="header_web_logo">
        <a href="/">
          <img
            src="Logo_ByTelmaSoares.png"
            alt="logo arctura"
            draggable={false}
            loading="lazy"
          ></img>
        </a>
      </div>
      <div className="socials">
        <a
          href="https://www.instagram.com/arctura_imageconsulting/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram size={20} fill="#C58C6E" />
        </a>
        <a
          href="https://www.tiktok.com/@arctura_imageconsulting/"
          target="_blank"
          rel="noreferrer"
        >
          <FaTiktok size={20} fill="#C58C6E" />
        </a>
      </div>
    </header>
  );
};

export default WebMenu;
