import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import "./Contacto.css";

const Contacto = () => {
  const [hasMessageBeenSent, setHasMessageBeenSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const textContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (textContainerRef.current) {
      observer.observe(textContainerRef.current);
    }

    return () => {
      if (textContainerRef.current) {
        observer.unobserve(textContainerRef.current);
      }
    };
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    try {
      await fetch("https://api.useplunk.com/v1/send", {
        method: "POST",
        body: JSON.stringify({
          to: "telma.soares@arctura.pt",
          subject: "Arctura | Nova mensagem de formulário",
          body: `${formData.get("nome")} - ${formData.get(
            "email"
          )} - ${formData.get("mensagem")}`,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer sk_3d0dc547bd162fcc61ebbc986d66ca1b6ec920c6c1028642",
        },
      });

      setSuccessMessage(
        "Iremos ver o seu e-mail e respondemos assim que for possível."
      );
      setHasMessageBeenSent(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "Ocorreu um erro a enviar o formulário, por favor tente novamente."
      );
    }
  };

  return (
    <div className="contacto_container" id="contacto">
      <img className="img_container" src="contact.webp" alt="sobre arctura" />
      <div className="text_container" ref={textContainerRef}>
        <p className="small_title">ENTRA EM CONTACTO</p>
        <p className="text">
          Estou aqui para te ajudar no teu glow-up. Seja para esclarecer
          dúvidas, agendar uma sessão ou simplesmente saber mais sobre os meus
          serviços, não hesites em entrar em contacto.
          <br />
          <br />
          Fico à espera para começar esta jornada contigo!
        </p>
        {!hasMessageBeenSent ? (
          <form onSubmit={handleFormSubmit}>
            <input
              name="nome"
              type="text"
              placeholder="Nome Completo"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("Por favor, preencha o seu nome.")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            ></input>
            <input
              name="email"
              type="email"
              placeholder="Email"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Por favor, preencha o seu email corretamente."
                )
              }
              onInput={(e) => e.target.setCustomValidity("")}
            ></input>
            <input
              name="mensagem"
              type="text"
              placeholder="Mensagem"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("Por favor, preencha a mensagem.")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            ></input>
            <p className={errorMessage ? "error" : "no-display"}>
              {errorMessage}
            </p>
            <button type="submit">Enviar</button>
          </form>
        ) : null}
        <p className={successMessage ? "success" : "no-display"}>
          <FaCheck size={12} style={{ marginRight: "12px" }} />
          {successMessage}
        </p>
      </div>
    </div>
  );
};

export default Contacto;
