import React, { useEffect, useRef } from "react";
import "./Quote.css";

const Quote = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <div className="quote_container">
      <img src="Star_white.png" alt="arctura star" className="quote_image" />
      <p className="quote_text" ref={textRef}>
        Style is a way to say{" "}
        <span className="quote_highlight">who you are</span> without having to
        speak.
      </p>
    </div>
  );
};

export default Quote;
