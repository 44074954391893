import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero_container">
      <div className="hero_text">
        <p>CONSULTORIA DE IMAGEM</p>
        <h1 className="hero_h1">
          UNIMOS ESTRELAS E HARMONIZAMOS O TEU ESTILO COM A TUA PERSONALIDADE
        </h1>
      </div>
      <img className="hero_img" src="hero.webp" alt="hero section" />
    </div>
  );
};

export default Hero;
