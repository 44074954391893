import Contacto from "./components/Contacto/Contacto";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Quote from "./components/Quote/Quote";
import Servicos from "./components/Servicos/Servicos";
import Sobre from "./components/Sobre/Sobre";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Quote />
      <Sobre />
      <Servicos />
      <Contacto />
      <Footer />
    </div>
  );
}

export default App;
