import React, { useEffect, useRef } from "react";
import { FiMenu } from "react-icons/fi";

import "./MobileMenu.css";

const MobileMenu = ({ isMenuOpen, setMenuOpen }) => {
  const dropdownMenuRef = useRef(null);

  const handleClickOutside = (e) => {
    if (!dropdownMenuRef.current?.contains(e.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div className="mobile-menu_container">
      <a href="/">
        <img
          src="Logo_ByTelmaSoares.png"
          alt="logo arctura"
          draggable={false}
          loading="lazy"
        ></img>
      </a>
      <button onClick={() => setMenuOpen(!isMenuOpen)}>
        <FiMenu size={30} color="#C58C6E" />
      </button>
      {isMenuOpen ? (
        <ul ref={dropdownMenuRef}>
          <a href="#sobre" onClick={() => setMenuOpen(false)}>
            Sobre
          </a>
          <a href="#serviços" onClick={() => setMenuOpen(false)}>
            Serviços
          </a>
          <a href="#contacto" onClick={() => setMenuOpen(false)}>
            Contacto
          </a>
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MobileMenu;
