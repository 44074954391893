import React, { useState } from "react";
import "./Servicos.css";
import Servico from "./Servico/Servico";

const Servicos = () => {
  const [servicoAberto, setServicoAberto] = useState("0");

  const handleToggle = (numero) => {
    setServicoAberto((prevAberto) => (prevAberto === numero ? null : numero));
  };

  const servicosData = [
    {
      numero: "1",
      titulo: "Coloração Pessoal",
      texto:
        "Descobre as cores que realçam a tua beleza natural e que melhor se adequam ao teu tom de pele, cabelo e olhos. Com base na teoria das cores, realizo uma análise detalhada para identificar a tua paleta ideal. Este processo permite-te criar combinações harmoniosas e escolher peças que te façam brilhar em qualquer ocasião.",
    },
    {
      numero: "2",
      titulo: "Preparação para Entrevistas de Emprego",
      texto:
        "A primeira impressão conta, especialmente em momentos decisivos como entrevistas de emprego ou apresentações importantes. Este serviço foca-se em construir uma imagem profissional e confiante, alinhada com o teu setor e personalidade. Juntos, trabalhamos no outfit ideal, postura e comunicação não-verbal para que te sintas seguro e preparado para impressionar.",
    },

    {
      numero: "3",
      titulo: "Análise de Estilo",
      texto:
        "O teu estilo é uma extensão da tua personalidade e da forma como vives o dia a dia. Nesta análise, trabalhamos juntos para identificar o teu estilo único, explorando as tuas preferências, rotina e objetivos. Com base nos resultados, criamos um guia personalizado que te orienta na construção de um guarda-roupa que te representa de forma autêntica e alinhada com o teu estilo de vida.",
    },
    {
      numero: "4",
      titulo: "Closet Detox",
      texto:
        "Liberta-te das peças que já não se ajustam à tua imagem e cria um guarda-roupa funcional e inspirador. No Closet Detox, analisamos o que tens, eliminamos o que já não serve e organizamos o que fica de forma prática e intuitiva. O objetivo é teres um guarda-roupa que realmente trabalhe a teu favor, onde cada peça reflete a tua identidade.",
    },
    {
      numero: "5",
      titulo: "Personal Shopping",
      texto:
        "Transforma as tuas compras numa experiência personalizada e eficaz. Este serviço ajuda-te a escolher peças que fazem sentido para o teu estilo, corpo e objetivos, evitando compras impulsivas ou desnecessárias. Com o meu acompanhamento, as tuas compras tornam-se mais estratégicas, garantindo que cada peça que adquires acrescenta valor ao teu guarda-roupa.",
    },
    {
      numero: "6",
      titulo: "Análise de Imagem Empresarial",
      texto:
        "A tua imagem no ambiente corporativo é fundamental para transmitires credibilidade e confiança. Neste serviço, analisamos a forma como te apresentas no trabalho, desde o vestuário até à postura e comunicação não-verbal. Com base nos princípios da psicologia e da comunicação visual, ofereço-te orientações personalizadas para que a tua imagem profissional esteja em sintonia com os teus objetivos de carreira e o impacto que desejas causar. Se procuras aprimorar a tua presença no ambiente empresarial, este serviço garante que transmites exatamente a mensagem que desejas.",
    },
    {
      numero: "7",
      titulo: "Consultoria de Imagem completa",
      texto:
        "- Entrevista e Análise Inicial: Análise das tuas necessidades e avaliação da coloração pessoal e proporções corporais, com foco em preferências e objetivos.\n- Estratégia de Imagem: Desenvolvimento de um plano personalizado com sugestões de peças, cortes e cores.\n- Closet Detox: Revisão do guarda-roupa para manter apenas o que reflete a nova imagem.\n- Personal Shopping: Compras estratégicas para complementar o guarda-roupa.\n- Montagem de Looks: Criação de 25 a 35 conjuntos para várias ocasiões.\n- Dossiê e Acompanhamento: Um guia personalizado e suporte contínuo durante 60 dias.",
    },
  ];

  return (
    <div className="servicos_container" id="serviços">
      <div className="title">
        <p className="small_title">SERVIÇOS ARCTURA</p>
        <p className="text">
          Na arctura disponibilizámos vários serviços que te vão ajudar a
          brilhar de dentro para fora
        </p>
      </div>
      <div className="list">
        {servicosData.map((servico) => (
          <Servico
            key={servico.numero}
            numero={servico.numero}
            titulo={servico.titulo}
            texto={servico.texto}
            isOpen={servicoAberto === servico.numero}
            onClick={() => handleToggle(servico.numero)}
          />
        ))}
      </div>
    </div>
  );
};

export default Servicos;
