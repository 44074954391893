import React, { useEffect, useRef } from "react";
import "./Sobre.css";

const Sobre = () => {
  const textContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (textContainerRef.current) {
      observer.observe(textContainerRef.current);
    }

    return () => {
      if (textContainerRef.current) {
        observer.unobserve(textContainerRef.current);
      }
    };
  }, []);

  return (
    <div className="sobre_container" id="sobre">
      <div className="sobre_text_container" ref={textContainerRef}>
        <p className="small_title">CONHECE A ARCTURA</p>
        <h2>Quando a psicologia e a moda se encontram.</h2>
        <p className="text">
          A Arctura nasceu da fusão entre uma formação sólida em Psicologia e
          uma paixão pela moda. Com um profundo interesse pelo comportamento
          humano, licenciei-me em Psicologia Clínica e conclui um mestrado na
          área, complementando a minha formação com uma especialização em
          Consultoria de Imagem.
          <br />
          <br />
          Na Arctura, o meu propósito é ajudar cada cliente a descobrir e
          expressar a sua melhor versão, num processo que começa de dentro para
          fora. Acredito que o estilo é uma expressão autêntica da nossa
          identidade e que a verdadeira transformação ocorre quando encontramos
          harmonia entre quem somos e o que vestimos.
          <br />
          <br />A minha abordagem foca sobretudo em vários princípios de
          psicologia pois entendo que a nossa forma de vestir só faz sentido
          quando está alinhada com a nossa essência e personalidade. Na Arctura,
          o meu trabalho vai muito alem de fazer um outfit, trata-se de
          despertar a confiança que faz qualquer peça ganhar vida. No final, não
          é apenas sobre o que vestimos, mas sobre a maneira como o fazemos.
        </p>
      </div>
      <img
        className="sobre_img_container"
        src="telma.jpeg"
        alt="sobre arctura"
      />
    </div>
  );
};

export default Sobre;
