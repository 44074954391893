import React, { useState } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import WebMenu from "./Web/WebMenu";
import MobileMenu from "./Mobile/MobileMenu";

const Header = () => {
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {isMobile ? (
        <MobileMenu isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      ) : (
        <WebMenu />
      )}
    </>
  );
};

export default Header;
